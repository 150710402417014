import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Divider } from '@mui/material';
import { styled } from '@mui/system';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    padding: theme.spacing(3),
    boxShadow: '0 6px 10px rgba(0,0,0,0.15)',
    backgroundColor: '#f7f9fc',
    color: '#333',
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2196f3',
  color: 'white',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2aad3d',
  color: 'white',
  padding: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#007d00',
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.dark,
}));

const ReviewWindow = ({ open, title, data, onConfirm, onCancel }) => {

  const parameters_methods_dict = {
    "delta": "Lassosum2-pseudo: delta",
    "nlambda": "Lassosum2-pseudo: nlambda",
    "lambda_min_ratio": "Lassosum2-pseudo: lambda_min_ratio",
    "alpha": "LDpred2-pseudo: alpha",
    "p_seq": "LDpred2-pseudo: p_seq",
    "sparse": "LDpred2-pseudo: sparse",
    "kb": "C+T-pseudo: kb",
    "pval_thr": "C+T-pseudo: pval_thr",
    "r2": "C+T-pseudo: r2",
    "phi": "PRS-CS-auto: phi",
  };

  if (data && data.methods) {
    if (data.methods.includes("PRS-CS")) {
      data.methods = data.methods.map(method => method === "PRS-CS" ? "PRS-CS-auto" : method);
    }
    if (data.methods.includes("PRSCS")) {
      data.methods = data.methods.filter(method => method !== "PRSCS");
    }
    if (data.methods.includes("LDpred2")) {
      data.methods = data.methods.map(method => method === "LDpred2" ? "LDpred2-pseudo" : method);
    }
    if (data.methods.includes("Lassosum2")) {
      data.methods = data.methods.map(method => method === "Lassosum2" ? "Lassosum2-pseudo" : method);
    }
    if (data.methods.includes("C+T")) {
      data.methods = data.methods.map(method => method === "C+T" ? "C+T-pseudo" : method);
    }
  }


  return (
    <CustomDialog
      open={open}
      onClose={onCancel}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
    >
      <DialogTitle id="custom-dialog-title" sx={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#333' }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="custom-dialog-description" sx={{ color: '#555' }}>
          <SectionHeader>Job Information</SectionHeader>
          <Typography><strong>Job Name:</strong> {data.job_name}</Typography>
          <Typography><strong>Job Type:</strong> {data.type}</Typography>
          <Typography><strong>Job Methods:</strong> {data.methods && data.methods.join(', ')}</Typography>
          <Typography><strong>Ensemble PRS:</strong> {data.ensemble ? 'Yes' : 'No'}</Typography>
          <Typography><strong>Email Notification:</strong> {data.email}</Typography>
          <Divider sx={{ marginY: 2 }} />

          <SectionHeader>Parameters</SectionHeader>
          {data.paraDict && Object.entries(data.paraDict).length > 0 && !data.methods.includes("PRS-CS-auto") ? (
            Object.entries(data.paraDict).map(([key, value]) => (
              <Typography key={key}>
                <strong>{parameters_methods_dict[key]}:</strong> {value}
              </Typography>
            ))
          ) : (
            <Typography><i>Default Parameters</i></Typography>
          )}

          <Divider sx={{ marginY: 2 }} />

          <SectionHeader>Trait Information</SectionHeader>
          <Typography><strong>Source:</strong> {data.source}</Typography>
          {/* <Typography><strong>Details:</strong> {data.detail}</Typography> */}
          <Typography><strong>Name:</strong> {data.name}</Typography>
          <Typography><strong>Type:</strong> {data.trait_type}</Typography>
          <Typography><strong>Population:</strong> {data.population}</Typography>
          <Divider sx={{ marginY: 2 }} />

          <SectionHeader>Trait Details</SectionHeader>
          {data.column && Object.entries(data.column).length > 0 ? (
            Object.entries(data.column)
              .filter(([key, value]) => value !== "")
              .map(([key, value]) => (
                <Typography key={key}>
                  {key}: {value}
                </Typography>
              ))
          ) : (
            <Typography>Default Column Names</Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onConfirm} autoFocus>
          Submit
        </CustomButton>
        <CancelButton onClick={onCancel}>
          Cancel
        </CancelButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default ReviewWindow;
