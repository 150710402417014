import React from 'react';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';

const updates = [
  { date: "2025-01-25", version: "1.0.0", description: "PennPRS version 1.0.0 was released." },
  {
    date: "2024-11-19",
    version: "1.0.0",
    description: (
      <>
        The <Link href="https://pennprs.gitbook.io/pennprs" target="_blank">tutorial</Link> is available for users.
      </>
    ),
  },
  { date: "2024-10-13", version: "1.0.0", description: "PennPRS is now live!" },
  { date: "2024-09-25", version: "1.0.0", description: "Internal testing phase completed." },
  { date: "2024-05-15", version: "1.0.0", description: "Demo version of PennPRS is available." },
];

export const UpdatesPage = () => {
  return (
    <Container sx={{ mt: 8, maxWidth: '700px', p: 2 }}>
      <Typography
        variant="h4"
        component="h1"
        sx={{ fontWeight: 300, textAlign: 'center', m: 4 }}
      >
        Updates
      </Typography>
      <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #e0e0e0' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center' }}>
                Version
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center' }}>
                Date
              </TableCell>
              <TableCell sx={{ fontWeight: 600, fontSize: '1rem', textAlign: 'center' }}>
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {updates.map((update, index) => (
              <TableRow key={index}>
                <TableCell sx={{ textAlign: 'center', fontSize: '0.9rem' }}>
                  {update.version}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: '0.9rem' }}>
                  {update.date}
                </TableCell>
                <TableCell sx={{ textAlign: 'center', fontSize: '0.9rem' }}>
                  {update.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};
