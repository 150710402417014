import React from "react";
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FAQPage = () => {
  const generalQuestions = [
    {
      question: "Can PennPRS conduct large-scale PRS training across a large number of traits/diseases?",
      answer:
        "The online PennPRS cloud computing platform is designed to conduct quick, small-scale analysis with one single trait/disease outcome at a time. With available local computing resources, the offline PennPRS command- line tool can be downloaded and used for large - batch PRS model training with parallel computing.",
    },
    {
      question: "What SNP list does PennPRS analysis support?",
      answer:
        "PennPRS currently supports PRS model development based on approximately 1.2 million SNPs in the HapMap 3 [link: https://www.sanger.ac.uk/data/hapmap-3/] SNP list.",
    },
    {
      question: "Can I use summary statistics from a GWAS or meta-analyzed GWAS that includes individuals from multiple ancestral populations? And if so, what population should I specify for it?",
      answer:
        "Yes. Please choose the single-ancestry analysis pipeline and select the ancestral population from which the majority of GWAS samples are from. Or, for an optimal analysis, you can contact us at pennprs@googlegroups.com to conduct analysis with a more refined population match between GWAS summary data and LD reference data.",
    },
    {
      question: "Is there a recommended minimum GWAS sample size for the analysis?",
      answer:
        "There is no hard threshold for sample size of the input GWAS summary data that can be used as a inclusion criterion. For binary outcomes, a sufficient number of cases is required to obtain a powerful PRS model.Limited number of cases(e.g., several hundreds) may lead to a minimum power of the resulting PRS model.",
    },
    {
      question: "For multi-ancestry PRS model training, what can I do if there are multiple GWAS summary datasets are from the same ancestral population?",
      answer:
        "There are two options: (1) conduct a meta analysis to combine the multiple GWAS summary datasets into one for the population; (2) select one (e.g., largest or with the highest quality) of the summary datasets.",
    },
    {
      question: "For multi-ancestry PRS model training, should I include the summary datasets from GWAS with limited samples? What can be considered a minimum GWAS sample size for the analysis?",
      answer:
        "There is no hard threshold for GWAS sample size that can be used as a inclusion criterion for multi-ancestry analysis. We recommend that you consider running all of the following analyses: (1) single - ancestry analysis using the GWAS summary data with the largest sample size(e.g., EUR GWAS summary dataset), (2) single - ancestry analyses using GWAS summary dataset from each target population (if available), and (3) multi - ancestry analysis using all available GWAS summary datasets for multiple ancestral populations, and choose the optimal PRS model from these trained PRS models based on their performance in your downstream analyses.",
    },
  ];

  const possibleErrors = [
    {
      question: "ConnectionResetError: [Errno 104] Connection reset by peer.",
      answer:
        "This is a message sent by the GWAS Catalog when internal errors occur in the GWAS catalog during the data querying step. Resolution: please wait for at least 30 minutes and try again.",
    },
    {
      question: "TimeoutError: [Errno 110] Connection timed out.",
      answer:
        "This is another example of message sent by the GWAS Catalog when internal errors occur in the GWAS catalog during the data querying step. Resolution: please wait for at least 30 minutes and try again.",
    },
    {
      question: "Wrong GWAS Catalog ID. Failed to download the file from GWAS Catalog. Please ensure this ID is in the queryable data on https://pennprs.org/data.",
      answer:
        "This issue occurs when using non-existing Study Accesssion ID to query summary data from the GWAS Catalog. Resolution: please correct the Study Accession ID and try again.",
    },
  ];

  return (
    <Container sx={{ py: 6, mt: 6, mb: 2}}>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{ fontWeight: 300, textAlign: "center" }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center", maxWidth: "80%", margin: "0 auto" }}
        >
          If you have any questions, please check the FAQs below. If you can't find the answer you're looking for, please contact us in <a href="https://groups.google.com/g/pennprs" target="_blank" rel="noreferrer">here</a>.
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          General Questions about the PennPRS Platform
        </Typography>
        {generalQuestions.map((faq, index) => (
          <Accordion key={index} elevation={0} sx={{ border: "1px solid #ddd", borderRadius: 2, mb: 2}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`general-panel${index}-content`}
              id={`general-panel${index}-header`}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textSecondary">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
          Possible Errors
        </Typography>
        {possibleErrors.map((faq, index) => (
          <Accordion key={index} elevation={0} sx={{ border: "1px solid #ddd", borderRadius: 2, mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`error-panel${index}-content`}
              id={`error-panel${index}-header`}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" color="textSecondary">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};
