import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid
} from "@mui/material";
import MethodCard from "../../../component/MethodCard";
import { useDispatch, useSelector } from "react-redux";
import { setJobMethods } from "../../../store/reducers/job";


export const PreMethodBlock = ({ setStep }) => {

  const dispatch = useDispatch();
  let methods = useSelector((state) => state.job.jobMethods);

  const handlePRS = () => {
    if (methods.includes("PRSCS")) {
      methods = methods.filter((method) => method !== "PRSCS");
    }
    dispatch(setJobMethods(methods));
    setStep(3);
  }

  const handlePRSCS = () => {
    if (!methods.includes("PRSCS") || methods.length === 0) {
      // methods = [...methods, "PRSCS"];
      methods = ["PRSCS"];
    }
    dispatch(setJobMethods(methods));
    setStep(3);
  }


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 5,
        mb: 5,
      }}
    >
      <Box
        sx={{
          width: "95%",
          display: "flex",
          flexDirection: "column",
          mt: 5,
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >

        <Typography variant="h6" sx={{ mb: 2 }}>
          Please select one of the following options:
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundColor: "#f5f5f5",
        }}
      >
        <MethodCard
          name="Pseudo-Training (Recommended)"
          introduction={`C+T-pseudo\nLassosum2-pseudo\nLDpred2-pseudo`}
          onClick={() => handlePRS()}
        />
        <MethodCard
          name="Tuning-Parameter-Free Methods"
          introduction={`LDpred2-auto (Recommended)\nDBSLMM\nPRS-CS-auto`}
          onClick={() => handlePRSCS()}
        />
      </Box>

      <Grid item xs={4}>
        <Button
          variant="contained"
          type="submit"
          fullWidth
          onClick={() => setStep(1)}
          sx={{
            color: "white",
            backgroundColor: "grey",
            mt: 10,
            textTransform: "none",
            fontSize: 18,
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#0d47a1" },
          }}
        >
          Back to Previous Step
        </Button>
      </Grid>

    </Box>
  );
};
