import * as React from 'react';
import { TopBar} from './view/topbar';
import { WelcomePage } from './view/welcomepage';
import { HomePage } from './view/homepage';
import { TutorialPage } from './view/tutorialpage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { JobPage } from './view/jobpage';
import { ResultPage } from './view/resultpage';
import { SAPage } from './view/sapage';
import { MAPage } from './view/mapage';
import { FAQPage } from './view/faqpage';
import { UpdatesPage } from './view/updatepage';
import SystemDown from './systemdown';

const theme = createTheme({
  typography: {
    fontFamily: 'sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'sans-serif',
          textTransform: 'none', 
        },
      },
    },
  }
});

function NotFoundPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <a href="/" style={{ color: '#1976d2', textDecoration: 'none' }}>Go to Home</a>
    </div>
  );
}

function App() {

  // return <SystemDown />;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <TopBar />
          <Routes>
            <Route path="/login" element={<WelcomePage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/data" element={<TutorialPage />} />
            <Route path="/sa" element={<SAPage />} />
            <Route path="/ma" element={<MAPage />} />
            <Route path="/job" element={<JobPage />} />
            <Route path="/result" element={<ResultPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/updates" element={<UpdatesPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
