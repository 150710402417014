import React, { useEffect, useState } from "react";
import {
  Box,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  TableSortLabel,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";

const DataTable = ({
  columns,
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleGoToPage,
  totalPages,
  goToPage,
  setGoToPage,
  sortColumn,
  sortDirection,
  handleSort,
}) => (
  <Box style={{ maxWidth: "100%", margin: "20px auto" }}>
    <table style={{
      width: '100%',
      borderCollapse: 'collapse',
      margin: '20px 0',
      fontSize: '14px',
      textAlign: 'left',
      fontFamily: '"Arial", sans-serif',
      color: '#333',
      border: '1px solid #999',
    }}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              key={column.id}
              style={{
                padding: '10px',
                border: '1px solid #999',
                backgroundColor: '#f1f1f1',
                fontWeight: 'bold',
                letterSpacing: '0.5px',
                textTransform: 'uppercase',
                textAlign: 'center',
                cursor: column.sortable ? 'pointer' : 'default',
              }}
              onClick={() => column.sortable && handleSort(column.id)}
            >
              {column.label}
              {column.sortable && (
                <span style={{ marginLeft: '5px', fontSize: '12px' }}>
                  {sortColumn === column.id ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                </span>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <tr
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white',
            }}
          >
            {columns.map((column) => (
              <td
                key={column.id}
                style={{
                  padding: '10px',
                  border: '1px solid #999',
                  textAlign: 'center',
                }}
              >
                {column.id === "link" ? (
                  <a
                    href={row[column.id]}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#0056b3', textDecoration: 'underline' }}
                  >
                    {row[column.id] ? "View Study" : "N/A"}
                  </a>
                ) : (
                  row[column.id]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>

    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <button
        onClick={(e) => handleChangePage(e, page - 1)}
        disabled={page === 0}
        style={{
          margin: '0 5px',
          padding: '5px 10px',
          backgroundColor: page === 0 ? '#ccc' : '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          cursor: page === 0 ? 'not-allowed' : 'pointer',
        }}
      >
        Previous
      </button>

      <span style={{ margin: '0 10px', fontSize: '14px' }}>
        Page {page + 1} of {totalPages}
      </span>

      <button
        onClick={(e) => handleChangePage(e, page + 1)}
        disabled={page >= totalPages - 1}
        style={{
          margin: '0 5px',
          padding: '5px 10px',
          backgroundColor: page >= totalPages - 1 ? '#ccc' : '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          cursor: page >= totalPages - 1 ? 'not-allowed' : 'pointer',
        }}
      >
        Next
      </button>
    </div>

    {/* <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <input
        type="number"
        value={goToPage}
        onChange={(e) => setGoToPage(e.target.value)}
        style={{
          width: '60px',
          padding: '5px',
          marginRight: '10px',
          border: '1px solid #ccc',
          borderRadius: '3px',
        }}
        min={1}
        max={totalPages}
      />
      <button
        onClick={() => handleGoToPage(goToPage)}
        style={{
          padding: '5px 10px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '3px',
          cursor: 'pointer',
        }}
      >
        Go
      </button>
    </div> */}
    <div style={{
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      fontSize: '14px',
      color: '#333'
    }}>
      <span>
        Data displayed: {Math.min(rowsPerPage, rows.length - page * rowsPerPage)} of {rows.length}
      </span>
    </div>

  </Box>
);


export const TutorialPage = () => {
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("date");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [goToPage, setGoToPage] = useState(1);

  useEffect(() => {
    fetch("gwas_catalog.tsv")
      .then((response) => response.text())
      .then((text) => {
        const lines = text.split("\n");
        let data = lines.map((line) => {
          const fields = line.split("\t");
          if (fields.length === 6) {
            const [pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, link] = fields;
            return { pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, link };
          } else {
            console.warn(`Skipping line due to incorrect format: ${line}`);
            return null;
          }
        }).filter(row => row !== null);

        setFiles(data);
      })
      .catch((error) => {
        console.error("Error fetching GWAS data:", error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setPage(0);
  }, [searchTerm]);

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === "asc";
    const sortedFiles = [...files].sort((a, b) => {
      if (column === "date") {
        return isAsc
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      }
      return isAsc
        ? (a[column] || "").localeCompare(b[column] || "")
        : (b[column] || "").localeCompare(a[column] || "");
    });
    setFiles(sortedFiles);
    setSortColumn(column);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGoToPage = (newPage) => {
    const pageNumber = parseInt(newPage, 10);
    if (pageNumber >= 1 && pageNumber <= Math.ceil(files.length / rowsPerPage)) {
      setPage(pageNumber - 1);
    }
  };

  const filteredFiles = files.filter((file) =>
    file.pubmedId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    file.diseaseTrait.toLowerCase().includes(searchTerm.toLowerCase()) ||
    file.studyAccession.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredFiles.length / rowsPerPage);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column" mt={1} p={5}>
      <h1>GWAS Catalog Data</h1>

      <TextField
        label="Search PUBMED, Disease/Trait, or Study Accession"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: 5, width: 500 }}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={[
            { id: "studyAccession", label: "Study Accession", sortable: true },
            { id: "date", label: "Date", sortable: true },
            { id: "pubmedId", label: "PUBMED", sortable: true },
            { id: "diseaseTrait", label: "Disease/Trait", sortable: false },
            { id: "initialSampleSize", label: "Sample Size & Population", sortable: false },
            { id: "link", label: "Link", sortable: false },
          ]}
          rows={filteredFiles}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleGoToPage={handleGoToPage}
          totalPages={totalPages}
          goToPage={goToPage}
          setGoToPage={setGoToPage}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          handleSort={handleSort}
        />
      )}
    </Box>
  );
};
