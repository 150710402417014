import React, { useEffect } from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserEmail } from "../../store/reducers/user";
import { useNavigate, useLocation } from "react-router-dom";
import { resetALL } from "../../store/reducers/job";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout'
import { setUserName } from "../../store/reducers/user";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider, sendPasswordResetEmail, } from "firebase/auth";


export const TopBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.username);


  const isActive = (path) => location.pathname === path;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyJobs = () => {
    handleClose();
    navigate("/job");
  };

  const handleGetResult = () => {
    handleClose();
    navigate("/result");
  };

  const handleChangeEmail = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const newEmail = prompt("Enter your new email:");
      if (newEmail) {
        const password = prompt("Enter your current password:");
        const credential = EmailAuthProvider.credential(user.email, password);

        reauthenticateWithCredential(user, credential)
          .then(() => {
            return updateEmail(user, newEmail);
          })
          .then(() => {
            dispatch(setUserEmail(newEmail));
            alert("Email updated successfully!");
          })
          .catch((error) => {
            console.error("Error updating email:", error);
            alert("Failed to update email. Please try again.");
          });
      }
    } else {
      alert("No user is logged in.");
    }
  };

  const handleResetPassword = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      // const email = user.email; 
      const email = userEmail;
      sendPasswordResetEmail(auth, email)
        .then(() => {
          alert(`Password reset email sent to ${email}.`);
        })
        .catch((error) => {
          console.error("Error sending password reset email:", error);
          alert("Failed to send password reset email. Please try again.");
        });
    } else {
      alert("No user is logged in.");
    }
  };


  const handleLogOut = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      dispatch(setUserEmail(null));
      dispatch(setUserName(null));
      handleClose();
      navigate("/login");
    }
  };

  const handleSingleAncestry = () => {
    if (userEmail !== null) {
      console.log("User is logged in, navigating to /sa");
      dispatch(resetALL());
      navigate("/sa");
    } else {
      console.log("User is not logged in, navigating to /login");
      navigate("/login");
    }
  };

  const handleMultiAncestry = () => {
    if (userEmail !== null) {
      console.log("User is logged in, navigating to /ma");
      dispatch(resetALL());
      navigate("/ma");
    } else {
      console.log("User is not logged in, navigating to /login");
      navigate("/login");
    }
  };

  const handleJobCenter = () => {
    if (userEmail !== null) {
      console.log("User is logged in, navigating to /job");
      navigate("/job");
    } else {
      console.log("User is not logged in, navigating to /login");
      navigate("/login");
    }
  };

  const isLoggedIn = userEmail && userName;

  return (
    <AppBar
      position="fixed"
      color="default"
      style={{ backgroundColor: "#002244" }}
      elevation={2}
    >
      <Toolbar>
        <Box onClick={() => navigate("/")} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <img src="/Logo_PNG/FullColor.png" alt="PennPRS" style={{ width: "50px", height: "50px", marginRight: "5px" }} />
          <Typography variant="h5" style={{ marginRight: "16px", fontWeight: 'bold', color: 'white' }}>
            PennPRS
          </Typography>
        </Box>
        <Box display="flex" flexGrow={1}></Box>


        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, fontWeight: isActive('/') ? 'bold' : 'normal', textTransform: 'none', color: 'white' }}
          onClick={() => navigate("/")}
          variant={isActive('/') ? "outlined" : "text"}
        >
          Home
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/pipeline') ? 'bold' : 'normal', color: 'white' }}
          // onClick={() => navigate("/pipeline")}
          // variant={isActive('/pipeline') ? "outlined" : "text"}
          onClick={() => window.open('https://github.com/PennPRS/Pipeline', '_blank')}
        >
          Pipeline
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', color: 'white' }}
          // onClick={() => navigate("/tutorial")}
          // variant={isActive('/tutorial') ? "outlined" : "text"}
          onClick={() => window.open('https://pennprs.gitbook.io/pennprs', '_blank')}
        >
          Tutorial
        </Button>


        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/data') ? 'bold' : 'normal', color: 'white' }}
          onClick={() => navigate("/data")}
          variant={isActive('/data') ? "outlined" : "text"}
        >
          Queryable Data
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/result') ? 'bold' : 'normal', color: 'white' }}
          onClick={() => navigate("/result")}
          variant={isActive('/result') ? "outlined" : "text"}

        >
          Public Results
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, fontWeight: isActive('/sa') ? 'bold' : 'normal', textTransform: 'none', color: 'white' }}
          onClick={handleSingleAncestry}
          variant={isActive('/sa') ? "outlined" : "text"}

        >
          Single-Ancestry Analysis
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/ma') ? 'bold' : 'normal', color: 'white' }}
          onClick={handleMultiAncestry}
          variant={isActive('/ma') ? "outlined" : "text"}

        >
          Multi-Ancestry Analysis
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/faq') ? 'bold' : 'normal', color: 'white' }}
          onClick={() => navigate("/faq")}
          variant={isActive('/faq') ? "outlined" : "text"}

        >
          FAQs
        </Button>

        <Button
          color="inherit"
          style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', fontWeight: isActive('/job') ? 'bold' : 'normal', color: 'white' }}
          onClick={handleJobCenter}
          variant={isActive('/job') ? "outlined" : "text"}

        >
          Job Center
        </Button>


        {userEmail ? (
          <>
            <IconButton color="inherit" aria-label="menu" onClick={handleClick} sx={{ color: 'white' }}>
              <AccountBoxIcon style={{ fontSize: 35 }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: '#ffffff',
                  color: '#333',
                  borderRadius: 8,
                  width: 320,
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                {userName && (
                  <Typography variant="h1" sx={{ padding: '8px', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>
                    {userName}
                  </Typography>)}
                <Typography variant="h4" sx={{ padding: '2px', textAlign: 'center', fontSize: '1.1rem' }}>
                  {userEmail}
                </Typography>
              </Box>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate('/job');
                }}
                sx={{
                  padding: '16px 24px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#d0d0d0',
                  },
                }}
              >
                <ManageAccountsIcon sx={{ marginRight: '8px' }} />
                Manage Job
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleChangeEmail();
                }}
                sx={{
                  padding: '16px 24px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#d0d0d0',
                  },
                }}
              >
                <MarkunreadIcon sx={{ marginRight: '8px' }} />
                Change Email
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleResetPassword();
                }}
                sx={{
                  padding: '16px 24px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#d0d0d0',
                  },
                }}
              >
                <VpnKeyIcon sx={{ marginRight: '8px' }} />
                Reset Password
              </MenuItem>
              <MenuItem
                onClick={handleLogOut}
                sx={{
                  padding: '16px 24px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '1rem',
                  '&:hover': {
                    backgroundColor: '#e0e0e0',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#d0d0d0',
                  },
                }}
              >
                <LogoutIcon sx={{ marginRight: '8px' }} />
                Logout
              </MenuItem>
            </Menu>

          </>
        ) : (
          <Button
            color="inherit"
            style={{ marginRight: "16px", fontSize: 15, textTransform: 'none', color: 'black' }}
            onClick={() => navigate("/login")}
            variant="contained"
          >
            Login
          </Button>
        )}


      </Toolbar>
    </AppBar >
  );
};
