import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import apis from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import StatusBox from "../../../component/StatusBox";
import { setJobName, setJobEmailOpt, resetALL } from "../../../store/reducers/job";
import AlterWindow from "../../../component/AlertWindow";
import ReviewWindow from "../../../component/ReviewWindow";

export const ConfigureBlock = ({ setStep }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);

  const store_types = useSelector((state) => state.job.traitsType);
  const store_sources = useSelector((state) => state.job.traitsSource);
  const store_details = useSelector((state) => state.job.traitsDetail);
  const store_names = useSelector((state) => state.job.traitsName);
  const store_population = useSelector((state) => state.job.traitsPopulation);
  const store_col = useSelector((state) => state.job.traitsCol);

  // const store_methods = useSelector((state) => state.job.jobMethods).filter((method) => method !== "PRSCS");
  const store_methods = useSelector((state) => state.job.jobMethods);
  const store_ensemble = useSelector((state) => state.job.jobEnsemble);

  const store_jobname = useSelector((state) => state.job.jobName);
  const store_emailop = useSelector((state) => state.job.jobEmailOpt);

  const store_paraDict = useSelector((state) => state.job.paraDict);
  const store_editPara = useSelector((state) => state.job.editPara);

  const [name, setName] = useState(store_jobname);
  const [emailop, setEmailOp] = useState(store_emailop);
  const [reviewData, setReviewData] = useState({});

  const gridItemStyle = {
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 2,
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleBack = () => {
    dispatch(setJobName(name));
    dispatch(setJobEmailOpt(emailop));
    setStep(3);
  };

  const hanldJobSubmit = () => {
    if (name === "") {
      setOpen(true);
      return;
    }
    setReviewData({type: "Single Ancestry Analysis", methods: store_methods, ensemble: store_ensemble, source: store_sources, detail: store_details, name: store_names, job_name: name, population: store_population, column: store_col[0], paraDict: store_editPara ? store_paraDict : {} , email: emailop === true ? "Enabled" : "Disabled", trait_type: store_types});
    setConfirmOpen(true);
  };

  const handleSubmit = async () => {
    setConfirmOpen(false);
    setIsLoading(true);
    try {
      const response = await apis.addJob({
        userEmail: userEmail,
        jobEmailOpt: emailop,
        jobName: name,
        jobType: "single",
        jobMethods: store_methods,
        jobEnsemble: store_ensemble,
        traitsSource: store_sources,
        traitsDetail: store_details,
        traitsType: store_types,
        traitsName: store_names,
        traitsPopoulation: store_population,
        traitsCol: store_col,
        paraDict: store_paraDict,
      });
      dispatch(resetALL());
      setStep(5);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }
  , []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 5,
        mb: 5,
      }}
    >
      <AlterWindow
        open={open}
        onClose={() => setOpen(false)}
        message="Please enter a name for your job."
        title={"⚠️ Warning"}
      />
      <ReviewWindow
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        data={reviewData}
        title={"Job Confirmation"}
        onConfirm={handleSubmit}
      />
      <Box
        sx={{
          width: "95%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ddd",
          borderRadius: "5px",
          pt: 2,
          pl: 2,
          overflow: "auto",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 6, md: 12 }}
        >
          <Grid item xs={4} sx={gridItemStyle}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Job Name:
            </Typography>
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <TextField
              variant="outlined"
              label="Job Name"
              fullWidth
              sx={{ mb: 2 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <StatusBox
              message="Please provide a name for your job."
              status={name === "" ? "required" : "ok"}
            />
          </Grid>

          <Grid item xs={4} sx={gridItemStyle}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Email Notification:
            </Typography>
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <FormControlLabel
              control={
                <Switch
                  checked={emailop === true}
                  onChange={(e) => setEmailOp(!emailop)}
                  color="primary"
                  disabled={isLoading}
                />
              }
              label={emailop === true ? "Enabled" : "Disabled"}
              sx={{ fontWeight: "bold" }}
            />
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <StatusBox
              message="Please select whether you want to receive email notification for your job status."
              status="optional"
            />
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={handleBack}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
              disabled={isLoading}
            >
              Back to Previous Step
            </Button>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={hanldJobSubmit}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "Review and Submit"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
